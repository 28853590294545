var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-balances"},[_c('div',{staticClass:"d-flex flex-wrap justify-space-between"},[_c('div',{staticClass:"d-flex "},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('v-divider',{staticClass:"mx-12",attrs:{"vertical":""}}),_c('div',{staticClass:"d-flex flex-wrap"},[_c('GetReport',{attrs:{"type":_vm.constant.reports.REPORT_TYPE.BALANCES_INFO,"command":_vm.reportData}}),_c('v-checkbox',{staticClass:"ml-4",attrs:{"label":_vm.$t('Ignore empty in report')},model:{value:(_vm.reports.ignoreEmpty),callback:function ($$v) {_vm.$set(_vm.reports, "ignoreEmpty", $$v)},expression:"reports.ignoreEmpty"}}),_c('v-checkbox',{staticClass:"ml-4",attrs:{"label":_vm.$t('Ignore test/bot in report')},model:{value:(_vm.reports.ignoreTest),callback:function ($$v) {_vm.$set(_vm.reports, "ignoreTest", $$v)},expression:"reports.ignoreTest"}})],1)],1),_c('div',[_c('v-btn',{attrs:{"color":"success","outlined":"","height":"40px"},on:{"click":_vm.addBalanceToUser}},[_vm._v(" "+_vm._s(_vm.$t('ADD BALANCE'))+" ")])],1),_c('UserBalanceBalancesAddBalanceModal',{on:{"close":_vm.closeAddBalanceModal},model:{value:(_vm.addBalanceState),callback:function ($$v) {_vm.addBalanceState=$$v},expression:"addBalanceState"}})],1),_c('div',{staticClass:"user-balances__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-extra":""},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.userId,"username":row.userName}})]}},{key:"balanceTitle",fn:function(){return [(_vm.requestData.currencyId || _vm.requestData.currencyId === 0)?[_vm._v(" "+_vm._s(_vm.$t('tableHeader.Balance'))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")]:[_vm._v(" "+_vm._s(_vm.$t('tableHeader.Balance'))+" ")]]},proxy:true},{key:"balance",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.sciToDec(row.cumulativeBalance))+" "+_vm._s(_vm.additionalData.cumulativeCurrencyTitle)+" ")]}},{key:"referrer",fn:function(ref){
var row = ref.row;
return [(row.referrerId)?_c('User',{attrs:{"id":row.referrerId,"username":row.referrerName}}):_vm._e()]}},{key:"tags",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.getTags(row.userTags)),function(tag){return _c('Tag',{key:'tag-row-' + tag.id,attrs:{"color":tag.color,"class-name":"mr-1"}},[_vm._v(" "+_vm._s(tag.tagName)+" ")])}),1)]}},{key:"comment",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userComment)+" ")]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('FinanceBalancesUserBalancesExtra',{attrs:{"data":row},on:{"edit":_vm.editStaking}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }