//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import User from '~/components/User.vue';
import Tag from '~/components/tags/Tag.vue';
import GetReport from '~/components/GetReport.vue';
import constant from '~/const';
import UserBalanceBalancesAddBalanceModal from '../../UserBalance/UserBalanceBalances/UserBalanceBalancesAddBalanceModal.vue';
import FinanceBalancesUserBalancesExtra from './FinanceBalancesUserBalancesExtra.vue';
import Filters from './FinanceBalancesUserBalancesFilters.vue';

import tableConfig from './tableConfig';

export default {
  components: {
    Filters,
    User,
    Tag,
    UserBalanceBalancesAddBalanceModal,
    FinanceBalancesUserBalancesExtra,
    GetReport,
  },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      constant,
      reports: {
        ignoreEmpty: false,
        ignoreTest: false,
      },
      addBalanceState: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('users', ['getUserTagsByIds']),
    ...mapGetters('financeBalancesUserBalances', ['requestData']),

    ...mapState('financeBalancesUserBalances', [
      'page',
      'pages',
      'data',
      'additionalData',
      'sort',
    ]),

    reportData() {
      const { ignoreEmpty, ignoreTest } = this.reports;
      return {
        empty: !ignoreEmpty,
        ignoreUserTagList: ignoreTest ? constant.reports.IGNORE_USER_TAG_LIST : [],
      };
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('financeBalancesUserBalances', {
      loadDataAction: 'loadData',
      updateParaminingPercAction: 'updateParaminingPerc',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getTags(userTags) {
      if (!userTags) return [];

      const array = userTags.map((e) => e.id);
      return this.getUserTagsByIds(array);
    },

    async editStaking(data) {
      this.setGeneralProgress(true);
      try {
        await this.updateParaminingPercAction(data);
        this.loader = true;
        await this.loadDataAction({});
        this.loader = false;
        this.setSuccessNotification('Staking percent updated');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },

    addBalanceToUser() {
      this.addBalanceState = true;
    },
    closeAddBalanceModal() {
      this.addBalanceState = false;
    },
  },
};
